@charset "utf-8";

$themeColor: #26619b;

@import 'swiper/swiper-vars.scss';
@import 'swiper/swiper.scss';
@import 'swiper/components/a11y/a11y.scss';
@import 'swiper/components/controller/controller.scss';
@import 'swiper/components/effect-coverflow/effect-coverflow.scss';
@import 'swiper/components/effect-cube/effect-cube.scss';
@import 'swiper/components/effect-fade/effect-fade.scss';
@import 'swiper/components/effect-flip/effect-flip.scss';
@import 'swiper/components/lazy/lazy.scss';
@import 'swiper/components/navigation/navigation.scss';
@import 'swiper/components/pagination/pagination.scss';
@import 'swiper/components/scrollbar/scrollbar.scss';
@import 'swiper/components/thumbs/thumbs.scss';
@import 'swiper/components/zoom/zoom.scss';

.swiper-container {
  opacity: 0;
  // overflow-x: visible !important;
  // padding: 0 calc(calc(100% - 992px) / 2);
  transition: opasity 0.8s ease;
  // .swiper-slide {
  //   width: 80%;
  // }
  &._bgiUse {
    height: 100%;
    .swiper-slide {
      background-color: #eee;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      & > img {
        font-family: 'object-fit: cover;';
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
      // &:not(.swiper-slide-active) {
      //   opacity: 0.5;
      // }
    }
  }
  // .swiper-wrapper {
  //   transition-timing-function: linear;
  //   .swiper-slide {
  //     width: 960px;
  //   }
  // }
  .swiper-pagination {
    .swiper-pagination-bullet {
      background-color: rgba(#555, 0.8);
      border-radius: 0;
      display: inline-block;
      font-size: 12px;
      height: 3px;
      margin: 0 2px;
      opacity: 1;
      text-align: center;
      width: 40px;
    }
    .swiper-pagination-bullet-active {
      background-color: #fff;
    }
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  @media (max-width: 576px) {
    padding: 0;
    .swiper-button-prev,
    .swiper-button-next {
      height: 40px;
      width: 20px;
    }
  }
}
